import React, {useEffect, useState} from 'react';
import Api from "../Api";

function Product(props) {
    const [plus,setPLus] = useState(null)
    useEffect(() => {
        (async () => {
            try{
                const {data} = await Api.getPlusBlock(localStorage.getItem('atsLang') || 1)
                setPLus(data?.pluses[0].plus_translate[0])
            }catch (e) {

            }
        })()
    }, [])
    return (
        <div className="control_about " dangerouslySetInnerHTML={{__html:plus?.title}}/>

    );
}

export default Product;
