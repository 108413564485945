import React from 'react';
import {Helmet} from "react-helmet";
import {Link, NavLink} from "react-router-dom";

function NotFound(props) {
    return (
        <>
            <Helmet>
                Not found
            </Helmet>
            <div className="notFound">
                <h2>404</h2>
                <p>Page not found</p>
                <Link to="/">Go Home</Link>
            </div>
        </>
    );
}

export default NotFound;
