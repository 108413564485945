import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../component/Wrapper";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {buttons, input, log_reg, menu} from "../helpers/translate";
import plus from "../assets/img/icon/SentMailIcon.svg";
import qs from "query-string";
import Utils from "../helpers/Utils";
import x from "../assets/img/icon/x.svg";
import email from "../assets/img/icon/UserIcon.svg";
import pass from "../assets/img/icon/pass.svg";
import userIcon from "../assets/img/icon/user.svg";

function Confirm(props) {
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleRedirect = useCallback(async () => {
        window.location.href = `https://account.ats.am/registration?phone=${query.phone}`
    }, [location.search])
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const navigate = useNavigate()
    const removeHash = () => {
        navigate(location.pathname);
    };
    if (windowWidth > 786) {
        return (

                <div className="big_screen">
                    <div className="login_container"/>
                    <div className="login" style={{height: 270}}>
                        <div className="login_header">
                            <p style={{width:'50%'}}>
                                {log_reg.regT[localStorage.getItem('atsLang')]}
                            </p>
                            <span onClick={removeHash} style={{cursor:'pointer'}}>
                                <img src={x} alt=''/>
                            </span>
                        </div>
                        <div className="login_block">
                            <div>
                                <div className="login_block_input">

                                    <p style={{margin: '24px 0'}} className="login_block_text">{log_reg.regD[localStorage.getItem('atsLang') || 1]}</p>
                                    <p>{input.telegram[localStorage.getItem('atsLang') || 1]}</p>

                                </div>
                            </div>
                            <button onClick={handleRedirect} className="register_btn">
                                {log_reg.check[localStorage.getItem('atsLang') || 1]}
                            </button>
                        </div>
                    </div>
                </div>
        );
    }else {
        return (
            <div className="login">
                <div className="login_header justify-content-end">

                    <Link to={localStorage.getItem('ats_link') || '/'}>
                        <img src={x} alt=''/>
                    </Link>
                </div>
                <div className="login_header">
                    <p style={{width:'50%'}}>
                        {log_reg.regT[localStorage.getItem('atsLang')]}
                    </p>
                </div>
                <div className="login_block">
                    <div>
                        <div className="login_block_input">

                            <p className="login_block_text">{log_reg.regD[localStorage.getItem('atsLang') || 1]}</p>
                            <p>{input.telegram[localStorage.getItem('atsLang') || 1]}</p>

                        </div>
                    </div>
                    <button onClick={handleRedirect} className="register_btn">
                        {log_reg.check[localStorage.getItem('atsLang') || 1]}
                    </button>
                </div>
            </div>
        )

    }

}

export default Confirm;
