import React, {useEffect, useState} from 'react';
import Api from "../Api";
import _ from 'lodash'
import {Link} from "react-router-dom";
// const {HomeBlock_APP_API_URL} = process.env;
import {REACT_APP_API_URL} from '../config'

function ClientBlock({isHome}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(windowWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [client,setClient] = useState({})
    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.client(localStorage.getItem('atsLang'))
                setClient({images:data.clientImages.slice(0, isHome?6:8),data:data.client})
            }catch (e) {

            }
        })()
    }, [isHome])
    return (
        <>
            {isHome && windowWidth <= 1024?<hr className="first_hr"/>:null}
            <div className={isHome?"main_container"+" client":'clients'} >
                <div className="client_title">
                    <p>{isHome && !_.isEmpty(client)?client?.data?.home_translate[0].title:!_.isEmpty(client)?client?.data?.home_translate[0].desc:''}</p>
                    {/*{isHome && windowWidth > 426?<Link to="/">Learn more about our clients</Link>:null}*/}

                </div>
                <div className="client_top_figure">
                    {client.images?client.images.map(i => <figure className="client_figure" key={i.id}><img src={REACT_APP_API_URL + i.path} alt=''/></figure>):null}
                </div>
                {/*{isHome && windowWidth <= 426?<Link to="/">Learn more about our clients</Link>:null}*/}

            </div>
            {isHome && windowWidth > 1024?<div className="main_container">
                <hr/>

            </div>:isHome && windowWidth > 426?<hr/>:null}
        </>
    );
}

export default ClientBlock;
