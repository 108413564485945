import React, {useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';

import Scroll from "./component/Scroll";
import Home from "./page/Home";
import Fax from "./page/Fax";
import About from "./page/About";
import Developers from "./page/Developers";
import Integration from "./page/Integration";
import Partners from "./page/Partners";
import Login from "./page/Login";
import Register from "./page/Register";
import Confirm from "./page/Confirm";
import DropPass from "./page/DropPass";
// import Register from "./page/Register";
// import Crm from "./page/Crm";
// import CrmPage from "./page/CrmPage";
// import Fax from "./page/Fax";
// import ApiDocument from "./page/ApiDocument";
// import Partner from "./page/Partner";
// import ContactUs from "./page/ContactUs";
// import Local from "./page/Local";
import NotFound from "./page/NotFound";


function App() {

    useEffect(( ) => {
        if(+localStorage.getItem('atsLang') !== 1){
            document.getElementById('root').classList.add('arm')
        }else{
            document.getElementById('root').classList.add('eng')
        }
    }, [])
    return (
        <>
            <BrowserRouter basename="/">
                <Scroll/>
                <Routes>
                    <Route path="/" element={<Navigate to='/home'/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/crm" element={<Integration/>}/>
                    <Route path="/single_service/:service" element={<Fax/>}/>
                    <Route path="/api_document" element={<Developers/>}/>
                    <Route path="/partner" element={<Partners/>}/>
                    <Route path="/registered" element={<Confirm/>}/>
                    <Route path="/drop" element={<DropPass/>}/>
                    {/*<Route path="/not-found" element={<NotFound/>}/>*/}
                    <Route path="/*" element={<NotFound/>}/>
                    {/*<Route path="/crm/:title" element={<CrmPage/>}/>*/}
                    {/*<Route path="/api_document/:api" element={<ApiDocument/>}/>*/}

                </Routes>
            </BrowserRouter>
            <ToastContainer closeOnClick hideProgressBar/>
        </>
    );
}

export default App;
