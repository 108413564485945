import React, {useCallback, useState} from 'react';
import x from '../assets/img/icon/x.svg'
import {buttons, homeD, menu} from "../helpers/translate";
import {Link, NavLink} from "react-router-dom";
import userIcon from "../assets/img/icon/user.svg";
import plus from "../assets/img/icon/PasswordIcon.svg";
import big from "../assets/img/icon/Arrow 1.svg";
import small from "../assets/img/icon/Arrow 2.svg";
import us from "../assets/img/icon/us.svg";
import ru from "../assets/img/icon/ru.svg";
import arm from "../assets/img/icon/arm.svg";
import down from "../assets/img/icon/down.svg";

function AsideMenu({visible, setVisible}) {
    const [see, setSee] = useState(true)
    const handleLangChange = useCallback((lang) => {
        localStorage.setItem('atsLang', lang)
        window.location.reload()
    }, [])
    return (
        <div className="aside_menu" style={visible ? {right: 0} : {right: '-100vw'}}>
            <div className="close">
                <div className="select_lang">
                    <div className="selected_lang">
                        <img
                            src={+localStorage.getItem('atsLang') === 1 ? us : +localStorage.getItem('atsLang') === 2 ? ru : arm}
                            alt=""/>
                        <span>{+localStorage.getItem('atsLang') === 1 ? 'English' : +localStorage.getItem('atsLang') === 2 ? 'Русский' : 'Հայերեն'}</span>
                        <img style={{position:'absolute',right:0,top:5}} src={down} alt=""/>
                    </div>
                    <ul className="lang_select">
                        {+localStorage.getItem('atsLang') !== 3 ?
                            <li onClick={() => handleLangChange(3)}><img src={arm} alt=""/>
                                <span>Հայերեն</span>
                            </li> : null}
                        {+localStorage.getItem('atsLang') !== 2 ?
                            <li onClick={() => handleLangChange(2)}><img src={ru} alt=""/>
                                <span>Русский</span>
                            </li> : null}
                        {+localStorage.getItem('atsLang') !== 1 ?
                            <li onClick={() => handleLangChange(1)}><img src={us} alt=""/>
                                <span>English</span>
                            </li> : null}
                    </ul>
                </div>
                <img alt='' onClick={() => setVisible(false)} src={x}/>
            </div>
            <div>
                <nav>
                    <ul className="aside_nav">
                        {menu.map((l, i) => {
                            if (i <= 5) {
                                if (l.isParent) {
                                    return (
                                        <span key={l.id}>
                                        <li onClick={() => {
                                            setSee(!see)
                                        }} className="parent_li" key={l.id}><span>
                                            <img src={l.icon} alt=""/>
                                            {l.trans[localStorage.getItem('atsLang') || 1]}
                                        </span>


                                        </li>
                                        <ul style={see ? {} : {border: 'none'}} className="aside_nav_child">
                                            {see ? l.child.map((r) => (
                                                <li key={r.link} onClick={() => {
                                                    setVisible(false)
                                                }}><NavLink to={r.link}>
                                                    <div>
                                                        <img
                                                            className={window.location.pathname === '/final' + r.link ? '_big' : '_small'}
                                                            src={window.location.pathname === '/final' + r.link ? big : small}
                                                            alt=""/>

                                                        {r.trans[localStorage.getItem('atsLang') || 1]}
                                                    </div>
                                                    <img src={r.icon} alt=""/>

                                                </NavLink>
                                                </li>
                                            )) : null}
                                        </ul>
                                    </span>
                                    )
                                } else {
                                    return (
                                        <li key={l.id} onClick={() => {
                                        }}><NavLink to={l.link}>
                                            <img src={l.icon} alt=""/>
                                            {l.id === 1 && +localStorage.getItem('atsLang') === 3?'Գլխավոր':l.trans[localStorage.getItem('atsLang') || 1]}
                                        </NavLink></li>
                                    )
                                }
                            }


                        })}
                    </ul>
                </nav>
            </div>
            <div className="aside_auth">
                <h3>
                    {homeD.login[localStorage.getItem('atsLang') || 1]}
                </h3>
                <Link to="/login" className="simple_btn">
                    <img src={userIcon} alt=""/>
                    <span>{buttons.sign[localStorage.getItem('atsLang') || 1]}</span>
                </Link>
                <h3>
                    {homeD.register[localStorage.getItem('atsLang') || 1]}
                </h3>
                <Link to="/register" className="register_btn">
                    <img src={plus} alt=''/>
                    {buttons.top[localStorage.getItem('atsLang') || 1]}
                </Link>
            </div>
        </div>
    );
}

export default AsideMenu;
